
import React from 'react'
import { gql } from '@apollo/client'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { html } from '../../../utils/htmlParser'
import { nonNull } from '../../../utils/nonNull'
import { Disclaimer as DisclaimerBase } from '../../../components/disclaimer/disclaimer'
import { DisclaimerHomeFragment } from './__generated__/disclaimers'

export const StyledHomeDisclaimerSymbol = styled(Typography)`
  vertical-align: middle;
  font-size: ${props => props.theme.typography.pxToRem(10)};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  padding-right: ${props => props.theme.typography.pxToRem(3)};
`

export const StyledHomeDisclaimerBase = styled(DisclaimerBase)`
  & > p {
    display: inline;
  }
`
export const StyledHomeDisclaimerWrapper = styled(DisclaimerBase)`
  background: #f2f2f2;
  gap: 0;

  > div:first-child {
    background: #fff;
    padding-bottom: ${props => props.theme.typography.pxToRem(35)};
  }

  > div:nth-child(2) {
    padding-top: ${props => props.theme.typography.pxToRem(25)};
    padding-bottom: 0;
  }

  > div:nth-child(n+3) {
    padding-bottom: 0;
  }

  > div:last-child {
    padding-bottom: ${props => props.theme.typography.pxToRem(25)};
  }
`
export const StyledHomeDisclaimerTitle= styled(DisclaimerBase)`
  & > h5 {
    margin-left: ${props => props.theme.typography.pxToRem(20)};
  }
`

export type DisclaimerProps = {
    data?: DisclaimerHomeFragment[]
    general?: DisclaimerHomeFragment[]
  }

export const Disclaimer: React.FC<DisclaimerProps> = ({ data, general }) => {
  return (
    <>
      {((general && general.length > 0) || (data && data.length > 0)) &&
        <StyledHomeDisclaimerWrapper>
          <StyledHomeDisclaimerTitle id={`disclaimer_block_128`} title>{'INFORMATION YOU SHOULD KNOW'}</StyledHomeDisclaimerTitle>
          {general && nonNull(general).map((item, index) => {
            if (item.__typename === 'customDisclaimers_default_Entry' || item.__typename === 'homeLoanDisclaimers_default_Entry' || item.__typename === 'personalloandisclaimers_default_Entry') {
              return (
                <StyledHomeDisclaimerBase key={index} textBlock>
                  {item.disclaimerText && html(item.disclaimerText)}
                </StyledHomeDisclaimerBase>
              )
            }
  
            return null
          })}
          {data && nonNull(data).map((item, index) => {
            if (item.__typename === 'customDisclaimers_default_Entry' || item.__typename === 'homeLoanDisclaimers_default_Entry' || item.__typename === 'personalloandisclaimers_default_Entry') {
              return (
                <StyledHomeDisclaimerBase key={index} textBlock>
                  <StyledHomeDisclaimerSymbol key={index}>
                    {index+1}
                  </StyledHomeDisclaimerSymbol>
                  {item.disclaimerText && html(item.disclaimerText)}
                </StyledHomeDisclaimerBase>
              )
            }
    
            return null
          })}
        </StyledHomeDisclaimerWrapper>
      }
    </>
  )
}

export const DISCLAIMER_HOME_FRAGMENT = gql`
  fragment DisclaimerHomeFragment on EntryInterface {
    id
    title
    ... on customDisclaimers_default_Entry {
      disclaimerText
    }
    ... on homeLoanDisclaimers_default_Entry {
      disclaimerText
    }
    ... on personalloandisclaimers_default_Entry {
      disclaimerText
    }
  }
`